import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { getSituacaoAcademica, MESES } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCDropdown, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { IAlunoForm } from '../interfaces/IAlunos';
import FrequenciaAlunoTurmaDiaForm from './FrequenciaAlunoTurmaDiaForm';
import { getValue } from '@testing-library/user-event/dist/utils';

const FrequenciaAlunoTurmaDia = () => {
    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const tarefa = 'FrequenciaAlunoTurmaDiaForm';

    let defaultValues: IAlunoForm = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Nome: '',
        AlunoId: undefined,
    };

    const [alunos, setAlunos] = useState<any>(null);
    const [alunoFrequenciaTurmaDiaDialog, setAlunoFrequenciaTurmaDiaDialog] = useState<boolean>(false);
    const [aluno, setAluno] = useState<IAlunoForm>(defaultValues);
    const [globalFilter] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [turmas, setTurmas] = useState<any>();
    const [mes, setMes] = useState<any>(new Date().getMonth() + 1);
    const [pesquisarDesabilitado, setPesquisarDesabilitado] = useState<boolean>(true);
    const dt = useRef<any>(null);
    const menu = useRef<any>(null);

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

    const watchTurma = watch('Turma')

    const load = async () => {
        setLoading(true)
        const { data } = await api.get('/aluno/buscaTurmaLancamentoFaltaMes', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Nome: getValues()?.Nome,
                CodigoTurma: watchTurma
            }
        });

        if (data.length > 0) {
            const turmasLancamentoPorMes = data.filter((turma: any) => turma.TipoLancamentoFaltas == 2)

            setAlunos(turmasLancamentoPorMes)
        } else {
            setAlunos([])
        }

        setLoading(false)
    }

    const loadTurmas = async () => {
        const turma = await api.get('/turmas', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        })
        if (turma.data) {
            setTurmas(turma.data);
        } else {
            setTurmas([]);
        }
    }

    useEffect(() => {
        loadTurmas();
    }, []);


    const hideDialog = () => {
        setAlunoFrequenciaTurmaDiaDialog(false);
    };

    const editar = (aluno: any) => {
        setAluno(aluno);
        setAlunoFrequenciaTurmaDiaDialog(true);
    };

    const onSubmit = async (data: any) => {
        load();
    }

    const opcoes = [
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Exp. para Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                dt.current.exportCSV();
            }
        },
    ];

    const openMenu = (event: any) => {
        menu?.current?.toggle(event)
    };

    const handleTurmas = async (e: any) => {
        setValue('Turma', e.target.value);
    }

    const handleMes = (e: any) => {
        setMes(e.target.value);
        if (!e.target.value) {
            setAlunos([])
        }
    }

    useEffect(() => {
        setPesquisarDesabilitado(!mes);
    }, [mes]);

    if (loading)
        return <Loading />

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Consulta/Altera Frequência Alunos - Turma / Dia</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
                        <div className='grid'>
                            <div className="col-12 md:col-3">
                                <SCDropdown
                                    name="Turma"
                                    options={turmas}
                                    optionLabel='TurmaDescricao'
                                    optionValue='Codigo'
                                    placeholder='Selecione a Turma'
                                    label='Turma'
                                    value={watchTurma}
                                    onChange={handleTurmas}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="col-12 md:col-4">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="Nome"
                                    label='Nome'
                                    className='w-full'
                                />
                            </div>
                            <div className="col-12 md:col-2">
                                <SCDropdown
                                    name='Meses'
                                    options={MESES}
                                    optionLabel='des'
                                    optionValue='mes'
                                    placeholder='Selecione o Mes'
                                    label='Mês'
                                    value={mes}
                                    onChange={handleMes}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className='col-12 md:col-3 flex align-items-end justify-content-around'>
                                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} disabled={pesquisarDesabilitado} />
                                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                            </div>
                        </div>
                    </form>

                    <Divider />

                    {loading ? <Loading /> : (
                        <DataTable
                            size='small'
                            stripedRows
                            ref={dt}
                            value={alunos}
                            // onSelectionChange={(e) => setSelectedAluno(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            globalFilter={globalFilter}
                            emptyMessage="Nenhum registro encontrado."
                            header={null}
                            responsiveLayout="scroll">

                            <Column field="NumeroOrdem" header="Ordem" sortable headerStyle={{ width: 50 }}></Column>
                            <Column field="TurmaCodigo" header="Turma" sortable headerStyle={{ width: 100 }}></Column>
                            <Column field="Nome" header="Nome" sortable ></Column>
                            <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }}></Column>
                            <Column field="SituacaoAcademica" header="Situação" sortable
                                body={(rowData: any) => {
                                    return (
                                        <div className="flex align-items-center justify-content-between">
                                            <span>{getSituacaoAcademica(rowData.SituacaoAcademica)}</span>
                                        </div>
                                    );
                                }}
                            ></Column>

                            <Column body={(rowData: any) => {
                                return (
                                    <div className="flex align-items-center justify-content-between">
                                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} disabled={rowData.SituacaoAcademica !== 'M'} />
                                    </div>
                                );
                            }} style={{ width: 50 }}
                            />
                        </DataTable>
                    )}
                    <SCDialog maximized={!util.isDesktop()} visible={alunoFrequenciaTurmaDiaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
                        header={`Altera Frequência Alunos - Turma / Dia`}
                        modal className="p-fluid" onHide={hideDialog}
                    >
                        <FrequenciaAlunoTurmaDiaForm
                            setAlunoFrequenciaTurmaDiaDialog={setAlunoFrequenciaTurmaDiaDialog}
                            aluno={aluno}
                            reload={load}
                            mesSelecionado={mes}
                        />
                    </SCDialog>

                </div>
            </div>
        </div>
    );
};

export default FrequenciaAlunoTurmaDia;
