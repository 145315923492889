import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SCButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import { useAuth } from '../providers/auth';
import AlunoResumo from '../components/AlunoResumo';
import { IAlunoForm } from '../interfaces/IAlunos';
import Loading from '../components/Loading';

interface Props {
    aluno?: IAlunoForm;
    setAlunoFrequenciaTurmaDiaDialog(param: boolean): void;
    reload(): void;
    mesSelecionado: number;
}

const FrequenciaAlunoTurmaDiaForm: React.FC<Props> = ({ setAlunoFrequenciaTurmaDiaDialog, mesSelecionado, aluno, reload }) => {
    // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
    // por padrão vamos utilizar o mesmo nome do componente
    const tarefa = 'FrequenciaAlunoTurmaDiaForm';

    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const [dataAtual] = useState(new Date());
    const [diasSelecionados, setDiasSelecionados] = useState<Date[]>([]);
    const [carregado, setCarregado] = useState(false);
    const [frequencia, setFrequencia] = useState<any>(null);
    const [loading, setLoading] = useState(false)
    const dt = useRef<any>(null);

    const defaultValues: IAlunoForm = aluno || {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        AlunoId: undefined,
        CursoId: undefined,
    };

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

    async function load() {
        setLoading(true)
        const { data } = await api.get('/aluno', {
            params: { AlunoId: aluno?.AlunoId }
        })

        const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: aluno?.Matricula
            }
        });

        data.Turma = {
            Codigo: alunoperiodo.data.TurmaCodigo,
        };

        data.AlunosPeriodo = alunoperiodo.data;
        data.DataNascimento = data.DataNascimento ? new Date(data.DataNascimento) : new Date();

        reset(data);

        const frequencia = await api.get('/frequenciaDia/list', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: data.Matricula,
            }
        })

        setFrequencia(frequencia.data || [])
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    const onSubmit = async () => {
        try {
            const frequenciaFiltrada = frequencia.filter((item: any) => {
                const data = new Date(item.LancamentoData);
                return data.getMonth() + 1 === mesSelecionado;
            });

            const diasSelecionadosSet = new Set(
                diasSelecionados.map(data => data.toISOString().split('T')[0])
            );

            frequenciaFiltrada.forEach(async (registro: any) => {
                const dataRegistro = new Date(registro.LancamentoData).toISOString().split('T')[0];
                if (!diasSelecionadosSet.has(dataRegistro)) {
                    await api.delete('/frequenciaDia', {
                        params: {
                            FrequenciaDiaId: registro.FrequenciaDiaId
                        }
                    })
                }
            });

            diasSelecionados.forEach(dataSelecionada => {
                const dataSelecionadaStr = dataSelecionada.toISOString().split('T')[0];
                if (!frequenciaFiltrada.some((registro: any) => new Date(registro.LancamentoData).toISOString().split('T')[0] === dataSelecionadaStr)) {
                    let salvarFaltas = {
                        EmpresaId: periodoSelecionado?.EmpresaId,
                        Ano: periodoSelecionado?.Ano,
                        Sequencial: periodoSelecionado?.Sequencial,
                        Matricula: aluno?.Matricula,
                        LancamentoData: dataSelecionada
                    };
                    return api.post('/frequenciaDia', salvarFaltas);
                }
            });

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            setAlunoFrequenciaTurmaDiaDialog(false);
        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    }

    const cancelar = (e: any) => {
        setAlunoFrequenciaTurmaDiaDialog(false);
        reset();
    }

    const Calendario = () => {
        const diasSemana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

        const getDiasNoMes = (date: Date) => new Date(date.getFullYear(), mesSelecionado - 1, 0).getDate();
        const getPrimeiroDiaMes = (date: Date) => new Date(date.getFullYear(), mesSelecionado - 1, 1).getDay();

        useEffect(() => {
            if (frequencia?.length > 0 && !carregado) {
                const datasIniciais = frequencia
                    .map((item: any) => new Date(item.LancamentoData))
                    .filter((dataSelecionada: Date) => dataSelecionada.getMonth() + 1 === mesSelecionado);

                setDiasSelecionados(datasIniciais);
                setCarregado(true);
            }
        }, [frequencia, mesSelecionado]);

        const handleDateClick = (day: number | undefined) => {
            const date = new Date(dataAtual.getFullYear(), mesSelecionado - 1, day);
            setDiasSelecionados(item => {
                const exists = item.some(d => d.toDateString() === date.toDateString());
                return exists ? item.filter(d => d.toDateString() !== date.toDateString()) : [...item, date];
            });
        };

        const renderCalendario = () => {
            const diasNoMes = getDiasNoMes(dataAtual);
            const primeiroDiaMes = getPrimeiroDiaMes(dataAtual);
            let dias = [];

            for (let i = 0; i < primeiroDiaMes; i++) {
                dias.push(<td key={`empty-${i}`} className="p-3"></td>);
            }

            for (let dia = 1; dia <= diasNoMes; dia++) {
                const date = new Date(dataAtual.getFullYear(), mesSelecionado - 1, dia);
                const selecionado = diasSelecionados.some(d => d.toDateString() === date.toDateString());

                dias.push(
                    <td
                        key={dia}
                        onClick={() => handleDateClick(dia)}
                        className={`p-3 text-center cursor-pointer transition-all rounded-lg 
                            ${selecionado ? "bg-blue-500 text-white hover:bg-blue-600" : "hover:bg-gray-200"}`}
                    >
                        {dia}
                    </td>
                );
            }

            const totalCells = Math.ceil(dias.length / 7) * 7;
            while (dias.length < totalCells) {
                dias.push(<td key={`empty-end-${dias.length}`} className="p-3"></td>);
            }

            const semanas = [];
            for (let i = 0; i < dias.length; i += 7) {
                semanas.push(<tr key={i}>{dias.slice(i, i + 7)}</tr>);
            }

            return semanas;
        };

        return (
            <>
                <div className='text-center'>
                    <div className='inline-block'>
                        <div className="w-full flex justify-center items-center gap-4 text-center">
                            {/* AQUI TALVEZ VÁ O MÊS E ANO */}
                        </div>
                    </div>
                    <table className="w-full border-collapse">
                        <thead>
                            <tr>
                                {diasSemana.map(dia => (
                                    <th key={dia} className="p-3 font-medium text-gray-600">{dia}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{renderCalendario()}</tbody>
                    </table>
                </div>
            </>
        );
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className='pt-3' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                    <div className='grid'>
                        <AlunoResumo mostraFoto mostraTurma aluno={getValues() as IAlunoForm} />
                    </div>

                    {loading ? <Loading /> : <Calendario />}
                    <Divider />

                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                        <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                        <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
                    </div>
                </form>
            </FormProvider>
        </>

    );
};

export default FrequenciaAlunoTurmaDiaForm;
