import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDropdown } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { format, getMonth, parseISO } from 'date-fns';
import Loading from '../components/Loading';


const FrequenciaTurmaDia = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';

  const [globalFilter] = useState<any>(null);
  const [turmas, setTurmas] = useState<any>(null);
  const [listAlunos, setListAlunos] = useState<any>();
  const [listAlunosInicial, setListAlunosInicial] = useState<any>();
  const [saveTime, setSaveTime] = useState<string | null>(null);
  const [loading, setLoading] = useState(false)
  const [disableGravar, setDisableGravar] = useState<boolean>(true)
  const dt = useRef<any>(null);

  const methods = useForm();
  const { control, formState: { errors }, watch, setValue } = methods;

  const load = async () => {
    setValue('LancamentoData', new Date())

    const turma = await api.get('/turma/listaTurmas', {
      params: {
        TemProfessorPrincipal: '=',
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
      }
    })
    if (turma.data) {
      setTurmas(turma.data);
    } else {
      setTurmas([]);
    }
  }

  useEffect(() => {
    load();
  }, []);

  const watchTurma = watch('Turma')
  const watchData = watch('LancamentoData')

  const handleTurmas = async (e: any) => {
    setListAlunos([])
    setValue('Turma', e.target.value);
  }

  const searchAlunos = async () => {
    setLoading(true)
    setSaveTime('');
    const data = new Date(watchData)
    const formatedData = data.getFullYear() === 1969 ? null : format(data, 'yyyy-MM-dd')

    const alunos = await api.get('/alunosPeriodo/listaFrequencia', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Sequencial: periodoSelecionado?.Sequencial,
        Ano: periodoSelecionado?.Ano,
        TurmaCodigo: watchTurma,
        LancamentoData: formatedData,
      }
    })

    const alunosSelecionados = alunos.data.map((aluno: any) => ({
      ...aluno,
      Selecionado: aluno.LancamentoData !== null && aluno.LancamentoData.split('T')[0] === formatedData ? 1 : 0
    }));

    setListAlunos(alunosSelecionados)
    setListAlunosInicial(alunosSelecionados)
    setLoading(false)
  }

  const handleCheckboxChange = (rowData: any, checked: boolean) => {
    const updatedListAlunos = listAlunos.map((item: any) => {
      if (item.AlunosPeriodoId === rowData.AlunosPeriodoId) {
        return { ...item, Selecionado: checked ? 1 : 0 };
      }
      return item;
    });

    const algumSelecionado = updatedListAlunos.some((item: any) => item.Selecionado === 1);
    setDisableGravar(!algumSelecionado);
    setListAlunos(updatedListAlunos);
  };

  const saveState = async () => {
    try {
      listAlunos.forEach(async (itemAtt: any) => {
        const itemInicial = listAlunosInicial.find((item: any) => item.Matricula === itemAtt.Matricula)
        if (itemInicial) {
          if (itemAtt.Selecionado === 1 && itemInicial.Selecionado === 0) {
            const frequenciaExiste = await api.get('/frequenciaDia/list', {
              params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: itemAtt.Matricula,
              }
            })

            const dataReferencia = new Date(watchData);
            const mesReferencia = dataReferencia.getMonth();

            const temFrequenciaNoMes = frequenciaExiste.data.some((registro: any) => {
                const dataLancamento = new Date(registro.LancamentoData);
                return (
                    dataLancamento.getMonth() === mesReferencia
                );
            });

            if (temFrequenciaNoMes) {
              toast.showToast({ severity: 'error', summary: 'Atenção', detail: ALERTAS.erroGravar });
              return;
            } else {
              let salvarFaltas = {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: itemAtt.Matricula,
                LancamentoData: new Date(watchData)
              }

              await api.post('/frequenciaDia', salvarFaltas)
              toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            }
          } else if (itemAtt.Selecionado === 0 && itemInicial.Selecionado === 1) {
            await api.delete('/frequenciaDia', {
              params: {
                FrequenciaDiaId: itemAtt.FrequenciaDiaId
              }
            })
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
          }
        }
      })

      const currentTime = new Date();
      const formattedTime = `${currentTime.getHours()}h ${currentTime.getMinutes()}m`;
      setSaveTime(formattedTime);

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  useEffect(() => {
    if (watchData) {
      setListAlunos([]);
    }
  }, [watchData]);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Lançamento de Faltas - Turma / Dia</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid pt-2'>
            <div className="col-12 md:col-3">
              <SCCalendar
                control={control}
                name="LancamentoData"
                placeholder='Selecione a Data'
                label='Data de Lançamento'
                dateFormat='dd/mm/yy'
                mask='99/99/9999'
                style={{ width: '100%' }}
                onChange={(e) => {
                  setListAlunos([])
                }}
                maxDate={new Date()}
              />
            </div>
            <div className="col-12 md:col-5">
              <SCDropdown
                name="Turma"
                options={turmas}
                optionLabel='DescricaoCodigo'
                optionValue='Codigo'
                placeholder='Selecione a Turma'
                label='Turma'
                value={watchTurma}
                onChange={handleTurmas}
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-12 md:col-2">
            </div>
            <div className='col-12 md:col-2 flex align-items-end'>
              <Button
                label='Pesquisar'
                icon="pi pi-search"
                className="p-button-info p-button-outlined"
                onClick={searchAlunos}
                disabled={!watchData || !watchTurma}
              />
            </div>
          </div>

          <Divider />

          {loading ? (
            <Loading />
          ) : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={listAlunos}
              dataKey="id"
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll"
              showGridlines>

              <Column field="NumeroOrdem" header="Ordem" sortable headerStyle={{ width: 50 }} />
              <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
              <Column field="Nome" header="Nome" sortable headerStyle={{ width: 450 }} />
              <Column field='Selecionado' align={'center'} header="Falta" headerStyle={{ width: 100 }}
                body={(rowData: any) => (
                  <div className="col-12 flex justify-content-center">
                    <SCCheckbox
                      checked={rowData.Selecionado === 1}
                      onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                      id={rowData.ApagarId}
                      name="Selecionado"
                    />
                  </div>
                )} />
            </DataTable>
          )}
          < div className='grid flex justify-content-center mt-2'>
            <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'>
              {saveTime && <p style={{ color: '#689F38' }}>Faltas Atualizadas às {saveTime}</p>}
            </div>
            <div className='col-12 md:col-2 flex justify-content-end'>
              <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} disabled={disableGravar} onClick={saveState} />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default FrequenciaTurmaDia;
