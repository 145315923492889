import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCDropdown, SCFieldset, SCInputMask, SCInputNumber, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { CalculoMoviFin } from '../utilities/calculoMoviFin';
import Loading from '../components/Loading';

const AjustaSaldoFinalDia = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const [fontes, setFontes] = useState<any>(null);
  const [loading, setLoading] = useState(false)

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  // Watchs
  const watchData = watch('Data')
  const watchFonte = watch('FonteId')
  const watchSaldoAtualDinheiro = watch('SaldoAtualDinheiro')
  const watchSaldoAtualCheque = watch('SaldoAtualCheque')
  const watchNovoSaldoDinheiro = watch('NovoSaldoDinheiro')
  const watchNovoSaldoCheque = watch('NovoSaldoCheque')
  const watchObservacao = watch('Observacao')
  const watchNovoSaldoTotal = watch('NovoSaldoTotal')

  const loadFontes = async () => {
    try {
      const { data } = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });
      setFontes(data || []);

    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const noSaldoFonte = () => {
    showToastError('Fonte não tem saldo!');
    setValue('FonteId', null);
    setValue('Agencia', '');
    setValue('Conta', '');
    setValue('SaldoAtualDinheiro', '');
    setValue('SaldoAtualCheque', '');
    setValue('SaldoAtualTotal', '');
    setValue('NovoSaldoDinheiro', '')
    setValue('NovoSaldoCheque', '')
    setValue('NovoSaldoTotal', '')
  }

  const limpaCampos = () => {
    setValue('Data', null)
    setValue('FonteId', null)
    setValue('Agencia', '')
    setValue('Conta', '')
    setValue('SaldoAtualDinheiro', '')
    setValue('SaldoAtualCheque', '')
    setValue('SaldoAtualTotal', '')
    setValue('NovoSaldoDinheiro', '')
    setValue('NovoSaldoCheque', '')
    setValue('NovoSaldoTotal', '')
    setValue('Observacao', '')
  }

  const buscaSaldo = async () => {
    if (!watchData) {
      showToastError('Preencha a data');
      return false
    }

    if (!watchFonte) {
      showToastError('Preencha a fonte');
      return false
    }

    try {
      if (watchFonte) {
        try {
          const { data: saldoFonte } = await api.get('/movimentacaoFonte/consultaSaldo', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              FonteId: watchFonte,
              Data: format(new Date(watchData), 'yyyy-MM-dd')
            }
          });

          if (saldoFonte?.length > 0) {
            if (saldoFonte.Observacao !== null) {
              setValue('SaldoAtualDinheiro', saldoFonte[0]?.SaldoAjustadoDinheiro.toFixed(2))
              setValue('SaldoAtualCheque', saldoFonte[0]?.SaldoAjustadoCheque.toFixed(2))
              setValue('SaldoAtualTotal', saldoFonte[0]?.SaldoAjustadoTotal.toFixed(2))

            } else {
              setValue('SaldoAtualDinheiro', saldoFonte[0]?.SaldoAtualDinheiro.toFixed(2))
              setValue('SaldoAtualCheque', saldoFonte[0]?.SaldoAtualCheque.toFixed(2))
              setValue('SaldoAtualTotal', saldoFonte[0]?.SaldoAtualTotal.toFixed(2))
            }

          } else {
            noSaldoFonte()
          }

        } catch (error) {
          showToastError('Erro ao consultar saldo da fonte.');
        }
      }

    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const consultaEncerramentoCaixa = async (dataTransferencia: any, dataCredito: any) => {
    try {
      // Consulta para a data de transferência
      const { data: dataTransferenciaResponse } = await api.get('consultaEncerramento', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Operador: usuario?.Apelido,
          Data: format(new Date(dataTransferencia), 'yyyy-MM-dd'),
          Acao: 'F',
        },
      });

      console.log(dataTransferenciaResponse); // Exibe o resultado da consulta da data de transferência

      if (dataTransferenciaResponse.length > 0) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Caixa Encerrado para a Data de Transferência!' });
        return false;
      }

      // Consulta para a data de crédito
      const { data: dataCreditoResponse } = await api.get('consultaEncerramento', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Operador: usuario?.Apelido,
          Data: format(new Date(dataCredito), 'yyyy-MM-dd'),
          Acao: 'F',
        },
      });

      console.log(dataCreditoResponse); // Exibe o resultado da consulta da data de crédito

      if (dataCreditoResponse.length > 0) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Caixa Encerrado para a Data de Crédito!' });
        return false;
      }

      return true; // Retorna true se não houver problemas

    } catch (error) {
      console.error('Erro na consulta:', error);
      return false; // Retorna false em caso de erro
    }
  };

  // Função auxiliar para exibir toast de erro
  const showToastError = (message: string) => {
    toast.showToast({
      severity: 'error',
      summary: 'Atenção',
      detail: message,
      life: 5000
    });
    return false;
  };

  const showToastSuccess = (message: string) => {
    toast.showToast({
      severity: 'success',
      summary: 'Sucesso',
      detail: message,
      life: 5000,
    });
  };

  const onSubmit = async () => {

    // Campos criticados
    if (watchNovoSaldoDinheiro > watchSaldoAtualDinheiro) {
      showToastError("O novo saldo em dinheiro não pode ser maior que o saldo atual em dinheiro.");
      return;
    }

    if (watchNovoSaldoCheque > watchSaldoAtualCheque) {
      showToastError("O novo saldo em cheque não pode ser maior que o saldo atual em cheque.");
      return;
    }

    if (!watchObservacao) {
      showToastError("Preencha a observação.");
      return;
    }

    // Conversão de data e hora 
    const ajusteData = format(new Date(), 'yyyy-MM-dd');
    const ajusteHora = format(new Date(), 'HH:mm');

    try {

      setLoading(true)
      // Dados em comum
      const saldoAtual = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        MovimentacaoData: format(new Date(watchData), 'yyyy-MM-dd'),
        FonteId: watchFonte,
        Observacao: watchObservacao,
        SaldoAjustadoTotal: watchNovoSaldoTotal,
        AjusteOperador: usuario?.Apelido,
        AjusteData: ajusteData,
        AjusteHora: ajusteHora,
      };

      // Prepara os valores para ajuste

      // SaldoAnteriorTotal = SaldoAjustadoTotal
      //SaldoAnteriorDinheiro = SaldoAjustadoDinheiro
      //SaldoAnteriorCheque = SaldoAjustadoCheque

      const dados = {
        ...saldoAtual,
        SaldoAjustadoDinheiro: watchNovoSaldoDinheiro !== undefined ? watchNovoSaldoDinheiro : null,
        SaldoAjustadoCheque: watchNovoSaldoCheque !== undefined ? watchNovoSaldoCheque : null,
        SaldoAnteriorTotal: watchNovoSaldoTotal,
        SaldoAnteriorDinheiro: watchNovoSaldoDinheiro !== undefined ? watchNovoSaldoDinheiro : null,
        SaldoAnteriorCheque: watchNovoSaldoCheque !== undefined ? watchNovoSaldoCheque : null
      };

      // Verificando se há dados para ajustar
      if (dados.SaldoAjustadoDinheiro === null && dados.SaldoAjustadoCheque === null) {
        showToastError("Nenhum saldo para ajustar.");
        return;
      }

      // Buscando Parametro
      const parEntradacartao = await api.get('/parametros/getParametro', {
        params: {
          Codigo: 'ENTCARAU',
          Grupo: 'F',
          EmpresaId: 0,
          Ano: '0000',
          Sequencial: '0'
        }
      });

      console.log("BUSCA PARAMETRO", parEntradacartao.data)

      // Envia a atualização para a API
      await api.put('/movimentacaoFonte/saldoAjustado', dados);

      // Inicia os valores de movimentação da fonte
      await api.put('/movimentacaoFonte/iniciaValores', {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Data: format(new Date(watchData), 'yyyy-MM-dd')
      });

      // Calculo Movimentação Financeira
      const { data: diarioData } = await api.get('/diariofinanceiro/listaMovimentacao', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Data: format(new Date(watchData), 'yyyy-MM-dd'),
        },
      });

      if (diarioData) {
        console.log(`DIARIO DATA`, diarioData)
        diarioData.forEach(async (item: any) => {

          let valor = item.Valor + item.ValorMulta + item.ValorJuros
            + item.ValorComplementar01 + item.ValorComplementar02 + item.ValorComplementar03
            + item.ValorComplementar04 + item.ValorComplementar05 + item.ValorComplementar06;

          // valor = valor < 0 ? valor * -1 : valor;

          let dados = {
            EmpresaId: item.EmpresaId,
            MovimentacaoData: item.DataDisponivel,
            FonteId: item.FonteId
          }

          if (['BAIAUT'].includes(item.Tarefa)) {
            let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaDinheiro' }
            await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
          }

          if (['TRFFON'].includes(item.Tarefa)) {
            if (item.Opcao === 'TRD') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaTransferencia' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }

            if (item.Opcao === 'TRC') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaTransferencia' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }
          }

          if (['OUTPAG', 'RECPAG', 'RECADI'].includes(item.Tarefa)) {
            if (item.Tipo === '1') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaDinheiro' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }

            if (item.Tipo === '2') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaCheque' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }

            if (item.Tipo === '3') {

              if (parEntradacartao.data.Valor === '1') {
                let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaCartao' }
                await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
              }
            }

            if (item.Tipo === '4') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'EntradaOutro' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }
          }

          if (['APAGAR'].includes(item.Tarefa)) {
            if (item.Tipo === '1') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaDinheiro' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }

            if (item.Tipo === '2') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaCheque' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }

            if (item.Tipo === '3') {
              if (parEntradacartao.data.Valor === '1') {
                let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaOutro' }
                await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
              }
            }

            if (item.Tipo === '4') {
              let dadosParaAtualizar = { ...dados, Valor: valor, Campo: 'SaidaDinheiro' }
              await api.put('/movimentacaoFonte/atualizaMovimentacao', dadosParaAtualizar)
            }
          }


        });

        limpaCampos()
      }

      // Calcular Totais da Movimentação

      const { data: movimentacaoData } = await api.get('/movimentacaoFonte/listaMovimentacao', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Data: format(new Date(watchData), 'yyyy-MM-dd'),
        },
      });

      // Somando e enviando os valores
      await Promise.all(
        movimentacaoData.map(async (m: any) => {

          let dadosMovi = {
            EmpresaId: m.EmpresaId,
            Data: m.MovimentacaoData,
            FonteId: m.FonteId
          }

          let totalEntrada = m.EntradaDinheiro + m.EntradaCheque + m.EntradaCartao + m.EntradaTransferencia + m.EntradaOutro
          let totalSaida = m.SaidaDinheiro + m.SaidaCheque + m.SaidaTransferencia + m.SaidaOutro
          let totalSaldoAtualDinheiro = (m.SaldoAnteriorDinheiro + m.EntradaDinheiro + m.EntradaCartao + m.EntradaTransferencia + m.EntradaOutro) - (m.SaidaDinheiro + m.SaidaTransferencia + m.SaidaOutro)
          let totalSaldoAtualCheque = (m.SaldoAnteriorCheque + m.EntradaCheque) - (m.SaidaCheque)
          let totalSaldoAtual = totalSaldoAtualDinheiro + totalSaldoAtualCheque

          let encerramentoOpe = usuario?.Apelido
          let encerramentoFlag = true
          let encerramentoRef = 'AJUSTA SALDO FINAL DO DIA'

          let dadosEntradaAtualizar = { ...dadosMovi, Valor: totalEntrada, Campo: 'TOTALENTRADA' };
          await api.put('/movimentacaoFonte/atualizaValores', dadosEntradaAtualizar);

          let dadosSaidaAtualizar = { ...dadosMovi, Valor: totalSaida, Campo: 'TOTALSAIDA' };
          await api.put('/movimentacaoFonte/atualizaValores', dadosSaidaAtualizar);

          let dadosSaldoAtualDinheiro = { ...dadosMovi, Valor: totalSaldoAtualDinheiro, Campo: 'SALDOATUALDINHEIRO' };
          await api.put('/movimentacaoFonte/atualizaValores', dadosSaldoAtualDinheiro);

          let dadosSaldoAtualCheque = { ...dadosMovi, Valor: totalSaldoAtualCheque, Campo: 'SALDOATUALCHEQUE' };
          await api.put('/movimentacaoFonte/atualizaValores', dadosSaldoAtualCheque);

          let dadosSaldoAtual = { ...dadosMovi, Valor: totalSaldoAtual, Campo: 'SALDOATUALTOTAL' };
          await api.put('/movimentacaoFonte/atualizaValores', dadosSaldoAtual);

          let encerramentoFlg = { ...dadosMovi, Valor: encerramentoFlag, Campo: 'ENCERRAMENTOFLG' };
          await api.put('/movimentacaoFonte/atualizaValores', encerramentoFlg);

          let encerramentoOperador = { ...dadosMovi, Valor: encerramentoOpe, Campo: 'ENCERRAMENTOOPERADOR' };
          await api.put('/movimentacaoFonte/atualizaValores', encerramentoOperador);

          let encerramentoReferente = { ...dadosMovi, Valor: encerramentoRef, Campo: 'ENCERRAMENTOREFERENTE' };
          await api.put('/movimentacaoFonte/atualizaValores', encerramentoReferente);
        })
      );

      setLoading(false)
      // Apenas após todas as requisições serem concluídas
      showToastSuccess("Saldo ajustado com sucesso.");

    } catch (error: any) {
      console.error('Erro ao gravar movimentação da fonte:', error.message);
      showToastError("Ocorreu um erro ao ajustar o saldo. Tente novamente.");
      setLoading(false)
    }
  }

  // Carregando Fontes
  useEffect(() => {
    loadFontes()
  }, []);


  // Carregando Agencia e Conta
  useEffect(() => {
    if (watchFonte) {
      setValue('Agencia', fontes.find((f: any) => f.FonteId == watchFonte).BancoAgencia)
      setValue('Conta', fontes.find((f: any) => f.FonteId == watchFonte).BancoConta)

    } else {
      setValue('Agencia', '');
      setValue('Conta', '');
    }
  }, [watchFonte]);

  useEffect(() => {
    // Espelhando valores para novo saldo
    if (watchSaldoAtualDinheiro && watchSaldoAtualCheque) {
      setValue('NovoSaldoDinheiro', watchSaldoAtualDinheiro)
      setValue('NovoSaldoCheque', watchSaldoAtualCheque)
    }
  }, [watchSaldoAtualDinheiro, watchSaldoAtualCheque])

  // Resetar os outros campos
  useEffect(() => {
    setValue('FonteId', null);
    setValue('Agencia', '');
    setValue('Conta', '');
    setValue('BancoSaldo', '');
  }, [watchData, setValue]);

  // Validando se o novo valor é maior que o valor atual
  useEffect(() => {
    const novoSaldoDinheiro = parseFloat(watchNovoSaldoDinheiro || 0);
    const novoSaldoCheque = parseFloat(watchNovoSaldoCheque || 0);

    const novoSaldoTotal = novoSaldoDinheiro + novoSaldoCheque;

    setValue('NovoSaldoTotal', novoSaldoTotal.toFixed(2));
  }, [watchNovoSaldoDinheiro, watchNovoSaldoCheque]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading ? (
        <Loading />
      ) : (
        <div className="grid crud-demo">
          <div className="col-12">
            <div className="card">
              <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772b3' }}>
                <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Ajusta saldo final do dia</h5>
              </div>

              <div className='grid mt-3'>
                <div className="col-12 md:col-2">
                  <SCCalendar
                    control={control}
                    errors={errors}
                    label='Data do Saldo'
                    name='Data'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Data'
                  />
                </div>

                <div className="col-12 md:col-3">
                  <SCDropdown
                    name="FonteId"
                    errors={errors}
                    label='Fonte de Origem'
                    optionLabel='Codigo'
                    optionValue='FonteId'
                    options={fontes}
                    onChange={(e) => setValue('FonteId', e.target.value)}
                    style={{ width: '100%' }}
                    value={watchFonte}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    name="Agencia"
                    errors={errors}
                    label='Agência'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12, color: 'blue' }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    name="Conta"
                    errors={errors}
                    label='Conta'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12, color: 'blue' }}
                  />
                </div>

                <div className="col-12 md:col-2 mt-3">
                  <Button
                    label="Pesquisar"
                    icon="pi pi-search"
                    className="p-button-info w-full"
                    type="button"
                    onClick={buscaSaldo}

                  />
                </div>
              </div>

              <div className='grid'>
                <div className='col-6'>
                  <SCFieldset legend="Saldo Atual" className="pt-0 mt-2">
                    <div className='grid'>
                      <div className="col-12 md:col-4">
                        <SCInputText
                          name="SaldoAtualDinheiro"
                          errors={errors}
                          control={control}
                          label='Dinheiro'
                          disabled
                          style={{ width: '100%', fontWeight: "bold", fontSize: 12 }}
                        />
                      </div>

                      <div className="col-12 md:col-4">
                        <SCInputText
                          name="SaldoAtualCheque"
                          errors={errors}
                          control={control}
                          label='Cheque'
                          disabled
                          style={{ width: '100%', fontWeight: "bold", fontSize: 12 }}
                        />
                      </div>

                      <div className="col-12 md:col-4">
                        <SCInputText
                          name="SaldoAtualTotal"
                          errors={errors}
                          control={control}
                          label='Total'
                          disabled
                          style={{ width: '100%', fontWeight: "bold", fontSize: 12, color: 'blue' }}
                        />
                      </div>
                    </div>
                  </SCFieldset>
                </div>

                <div className='col-6'>
                  <SCFieldset legend="Novo Saldo" className="pt-0 mt-2">
                    <div className='grid'>
                      <div className="col-12 md:col-4">
                        <SCInputNumber
                          control={control}
                          name='NovoSaldoDinheiro'
                          errors={errors}
                          label='Dinheiro'
                          min={0}
                          max={10000}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          currency='BRL'
                          mode='currency'
                          locale='pt-BR'
                          inputStyle={{ fontSize: 12, fontWeight: "bold" }}
                        />
                      </div>

                      <div className="col-12 md:col-4">
                        <SCInputNumber
                          control={control}
                          name='NovoSaldoCheque'
                          errors={errors}
                          label='Cheque'
                          min={0}
                          max={10000}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          currency='BRL'
                          mode='currency'
                          locale='pt-BR'
                          inputStyle={{ fontSize: 12, fontWeight: "bold" }}
                        />
                      </div>

                      <div className="col-12 md:col-4">
                        <SCInputNumber
                          control={control}
                          name='NovoSaldoTotal'
                          errors={errors}
                          label='Total'
                          min={0}
                          max={10000}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          currency='BRL'
                          mode='currency'
                          locale='pt-BR'
                          disabled
                          inputStyle={{ color: "blue", fontSize: 12, fontWeight: "bold" }}
                        />
                      </div>
                    </div>
                  </SCFieldset>
                </div>
              </div>

              <Divider />

              <div className='grid'>
                <div className="col-12 md:col-12">
                  <SCInputText
                    control={control}
                    name="Observacao"
                    errors={errors}
                    label='Observação'
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className="grid">
                <div className="col-12 flex justify-content-end align-items-end">
                  <div className="col-12 md:col-2 mt-2">
                    <Button
                      label="Ajustar Saldo"
                      icon="pi pi-plus-o"
                      className="p-button-success"
                      type="button"
                      style={{ width: '100%' }}
                      onClick={onSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      )}

    </form >
  );
};

export default AjustaSaldoFinalDia;