import { useState, useEffect, useRef, SetStateAction } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { CheckboxChangeParams } from 'primereact/checkbox';
import Loading from '../components/Loading';

const EncerramentoMoviFin = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'EncerramentoMoviFin';

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [aPagar, setAPagar] = useState<any>([]);
  const [usuarios, setUsuarios] = useState<any>([]);
  const [valorTotalSelecionado, setValorTotalSelecionado] = useState(0);
  const [loading, setLoading] = useState(false)
  const [disableExecutar, setDisableExecutar] = useState<boolean>(true)

  const methods = useForm();
  const { control, watch, getValues, setValue } = methods;

  const watchUsuario = watch('UsuarioId')
  const watchDataI = watch('DataInicial')
  const watchDataF = watch('DataFinal')

  const onSubmit = async () => {
    try {
      let valorTotal = 0;
      const hours = String(new Date().getHours()).padStart(2, '0');
      const minutes = String(new Date().getMinutes()).padStart(2, '0');
      const formattedTime = `${hours}:${minutes}`;

      aPagar.forEach(async (item: any) => {
        if (item.Selecionado === 1) {
          console.log(item)
          const updateItensApagar = {
            LiquidaFaturaIdAnterior: item.LiquidaFaturaId,
            LiquidaFaturaId: 0,
            FaturaCartao: false,
          }
          const updateItemLiquidaFatura = {
            LiquidaFaturaId: item.LiquidaFaturaId,
            Excluido: true,
            ExcluidoData: new Date(),
            ExcluidoHora: formattedTime,
            ExcluidoOperador: usuario?.Apelido,
          }

          await api.put('/apagar/estornoFatura', updateItensApagar);
          await api.put('/liquidaFatura/estornoFatura', updateItemLiquidaFatura);
        }
      });

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });


    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const prevValidation = useRef(true);

  useEffect(() => {
    const dataInicial = watchDataI;
    const dataFinal = watchDataF;

    if (!dataInicial || !dataFinal) {
      setDisableExecutar(true);
      return;
    }

    const isValid = dataInicial <= dataFinal;
    setDisableExecutar(!isValid);

    // Só mostra o toast se mudou de válido para inválido
    if (!isValid && prevValidation.current) {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Data inicial maior que a Data Final',
      });
    }

    prevValidation.current = isValid;
  }, [watchDataI, watchDataF]);



  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Encerra Movimentação Financeira</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>
          <SCFieldset legend='Período para o encerramento' className='col-12 mt-2 pt-0'>
            <div className='grid'>
              <div className='grid col-12 justify-content-center'>
                <div className="col-12 sm:col-6 md:col-3 lg:col-2">
                  <SCCalendar
                    control={control}
                    label='Data Inicial'
                    name='DataInicial'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Data Inicial'
                  />
                </div>

                <div className="col-12 sm:col-6 md:col-3 lg:col-2">
                  <SCCalendar
                    control={control}
                    label='Data Final'
                    name='DataFinal'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Data Final'
                  />
                </div>
              </div>
            </div>
          </SCFieldset>

          <SCFieldset legend='Observações' className='col-12 mt-2 pt-0'>
            <div className='grid justify-content-center gap-4'>
              <span style={{ color: 'blue' }}>O sistema recua para a <strong>ÚLTIMA DATA PROCESSADA</strong> anterior a data informada para reprocessar os saldos.</span>
              <span style={{ color: 'blue' }}>Mesmo quando não havendo movimentação na data, o sistema cria os saldos com base no último saldo calculado.</span>
            </div>
          </SCFieldset>

          <div className='grid flex justify-content-center'>
            <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
            <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
              <Button label='Executar' icon="pi pi-plus-o" className="p-button-danger" type='button' style={{ width: '100%' }} onClick={onSubmit} disabled={disableExecutar} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );


};

export default EncerramentoMoviFin;